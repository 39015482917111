<template>
  <div class="card-wrapper">
    <router-link :to="{ name: 'Home' }">
      <img src="../../assets/images/logo.png" alt="Reduct logo" />
    </router-link>

    <BackButton :parent-route="{ name: 'Login' }"></BackButton>

    <div class="card">
      <div class="card-header">
        <div class="card-header-title">{{ $t("password_forgot") }}</div>
      </div>

      <div class="card-content">
        <form @submit.prevent="submit">
          <CustomInput
            v-model="emailAddress"
            :label="$t('email')"
            :horizontal="false"
            :type="'email'"
            :icon="'email'"
          >
          </CustomInput>

          <b-button
            expanded
            type="is-primary"
            native-type="submit"
            class="mt-5"
            :loading="loading"
          >
            {{ $t("submit") }}
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BackButton from "../../components/BackButton";
import CustomInput from "../../components/forms/CustomInput";

export default {
  name: "ForgotPassword",
  components: { BackButton, CustomInput },
  data() {
    return {
      loading: false,
      emailAddress: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    if (this.user) {
      this.$router.push({ name: "Account" });
    }
  },
  methods: {
    ...mapActions(["resetPasswordRequest"]),
    submit() {
      this.loading = true;

      this.resetPasswordRequest({ emailAddress: this.emailAddress })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("password_forgot_sent"),
            type: "is-primary",
          });
        })
        .catch((error) => {
          if (error.message === "TOO_MANY_ATTEMPTS") {
            this.$buefy.toast.open({
              message: this.$t("too_many_attempts"),
              type: "is-danger",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
